/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:709f53f7-fb13-4cb9-b739-bdfcdaf5edcc",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_AnpJbxZg0",
    "aws_user_pools_web_client_id": "5h579nchgutpiabpg757ceb0i3",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "covidSeriesAPI",
            "endpoint": "https://y15sdtad9d.execute-api.ap-southeast-2.amazonaws.com/develop",
            "region": "ap-southeast-2"
        }
    ],
    "aws_user_files_s3_bucket": "dpddatasets-develop",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
